/**
 * @name: utils
 * @author: mahuanhuan
 * @date: 2024/7/26
 * @description：
 * @update: 2024/7/26
 */

// 随机获取一个元素的函数
export const getRandomElement = (arr: Array<string>) => {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
};
